.consoleButton {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  color: #fff;
  border-radius: 4px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.consoleAlert {
  width: 100%;
  text-align: center;
}

.footerButtons {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.footerButtons > * {
  margin-left: 0.5em;
}

.footerButtons:first-child {
  margin-left: 0;
}
