.scrollableMenu {
  height: auto;
  max-height: 300px;
  overflow-x: hidden;
}

.roleTypeValue {
  width: 50% !important;
}

.tooltip {
  max-width: 25em;
}
