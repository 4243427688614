.keyValueTable th {
  white-space: nowrap;
  width: 1%;
  background-color: rgba(0,0,0,0);
  color: inherit;
}

.keyValueTable td {
  width: 99%;
  background-color: rgba(0,0,0,0);
  color: inherit;
}
