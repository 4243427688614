.filterableAccountList {
  height: 100%;
  display: flex;
  flex-direction: column;

  > .header {
    padding-left: 1em;
    padding-top: 1em;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;

    > .dropdownButton {
      display: none;
      cursor: pointer;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  > .collapse {
    flex: 1 1 0;

    > .body {
      height: 100%;

      display: flex;
      flex-direction: column;

      > .searchBar {
        width: auto;
        flex: 0 0 auto;

        > .searchBarRow {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 5px;
        }
      }

      > .accountList {
        flex: 1 1 auto;

        display: block;
        height: 0; /* Must be set or auto. Flex box will cause it to adjust properly */
        overflow-y: auto;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .filterableAccountList {
    > .header {
      > .dropdownButton {
        display: inline;
      }
    }

    > .collapse {
      > .body {
        > .accountList {
          flex: 0 1 100%;
          height: auto;
        }
      }
    }
  }
}
