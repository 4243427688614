.filterableIamRoleList {
  height: 100%;

  display: flex;
  flex-direction: column;

  > .header {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;

    > .searchBar {
      flex: 1 1 auto;
    }

    > .newButton {
      flex: 0 0 auto;
      margin-left: 1rem;
    }
  }

  > .iamRoleList {
    flex: 1 1 0;
  }
}
