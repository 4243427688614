.gearMenu {
  float: right;
  cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .gearMenu {
    display: none;
  }
}

.cardWrapper {
  margin: 10px 0;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  transition: height 0.5s ease;

  &:hover {
    -webkit-box-shadow: 0px 0px 21px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 21px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 21px -6px rgba(0, 0, 0, 0.75);
  }
}

.userDetails {
  margin-bottom: 20px;
}
