.failureContainer {
  border: 2px dashed red;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .failure {
    flex: 1 1 0;

    text-align: center;
    color: white;
    background-color: #f44;
    border-radius: 1em;
    padding: 1em {
      left: 2em;
      right: 2em;
    }
    margin: 5%;
  }
}
