.main {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.initialHeart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.initialHeart > * {
  font-size: 50px;
  color: white;
  position: absolute;
  top: -5vh;
}

@keyframes heartfall {
  0% {
    transform: translate3d(var(--left-ini), 110vh, 0);
  }

  100% {
    transform: translate3d(var(--left-end), 0, 0);
  }
}

.heart:nth-child(2n) {
  filter: blur(1px);
  font-size: 40px;
}

.heart:nth-child(6n) {
  filter: blur(2px);
  font-size: 30px;
}

.heart:nth-child(10n) {
  filter: blur(5px);
  font-size: 30px;
}

.heart:nth-child(1) {
  --size: 0.8vw;
  --left-ini: 0vw;
  --left-end: -1vw;
  left: 70vw;
  animation: heartfall 9s linear infinite;
  animation-delay: -1s;
}

.heart:nth-child(2) {
  --size: 0.2vw;
  --left-ini: -7vw;
  --left-end: 10vw;
  left: 65vw;
  animation: heartfall 15s linear infinite;
  animation-delay: -8s;
}

.heart:nth-child(3) {
  --size: 1vw;
  --left-ini: 6vw;
  --left-end: 6vw;
  left: 1vw;
  animation: heartfall 9s linear infinite;
  animation-delay: -7s;
}

.heart:nth-child(4) {
  --size: 0.2vw;
  --left-ini: -3vw;
  --left-end: 9vw;
  left: 88vw;
  animation: heartfall 14s linear infinite;
  animation-delay: -5s;
}

.heart:nth-child(5) {
  --size: 0.4vw;
  --left-ini: -2vw;
  --left-end: -9vw;
  left: 74vw;
  animation: heartfall 6s linear infinite;
  animation-delay: -4s;
}

.heart:nth-child(6) {
  --size: 0.2vw;
  --left-ini: 5vw;
  --left-end: 1vw;
  left: 35vw;
  animation: heartfall 6s linear infinite;
  animation-delay: -7s;
}

.heart:nth-child(7) {
  --size: 0.4vw;
  --left-ini: -2vw;
  --left-end: -1vw;
  left: 27vw;
  animation: heartfall 10s linear infinite;
  animation-delay: -8s;
}

.heart:nth-child(8) {
  --size: 1vw;
  --left-ini: -9vw;
  --left-end: -2vw;
  left: 69vw;
  animation: heartfall 8s linear infinite;
  animation-delay: -8s;
}

.heart:nth-child(9) {
  --size: 0.2vw;
  --left-ini: -1vw;
  --left-end: -5vw;
  left: 84vw;
  animation: heartfall 11s linear infinite;
  animation-delay: -6s;
}

.heart:nth-child(10) {
  --size: 0.2vw;
  --left-ini: 7vw;
  --left-end: -9vw;
  left: 82vw;
  animation: heartfall 10s linear infinite;
  animation-delay: -3s;
}

.heart:nth-child(11) {
  --size: 0.6vw;
  --left-ini: -2vw;
  --left-end: -1vw;
  left: 48vw;
  animation: heartfall 10s linear infinite;
  animation-delay: -1s;
}

.heart:nth-child(12) {
  --size: 0.2vw;
  --left-ini: 5vw;
  --left-end: 6vw;
  left: 39vw;
  animation: heartfall 15s linear infinite;
  animation-delay: -8s;
}

.heart:nth-child(13) {
  --size: 0.2vw;
  --left-ini: 6vw;
  --left-end: 5vw;
  left: 3vw;
  animation: heartfall 9s linear infinite;
  animation-delay: -8s;
}

.heart:nth-child(14) {
  --size: 0.8vw;
  --left-ini: -5vw;
  --left-end: -2vw;
  left: 49vw;
  animation: heartfall 11s linear infinite;
  animation-delay: -8s;
}

.heart:nth-child(15) {
  --size: 0.6vw;
  --left-ini: 10vw;
  --left-end: 4vw;
  left: 77vw;
  animation: heartfall 7s linear infinite;
  animation-delay: -4s;
}

.heart:nth-child(16) {
  --size: 0.8vw;
  --left-ini: -3vw;
  --left-end: 1vw;
  left: 86vw;
  animation: heartfall 14s linear infinite;
  animation-delay: -8s;
}

.heart:nth-child(17) {
  --size: 1vw;
  --left-ini: 6vw;
  --left-end: -7vw;
  left: 18vw;
  animation: heartfall 9s linear infinite;
  animation-delay: -6s;
}

.heart:nth-child(18) {
  --size: 1vw;
  --left-ini: -9vw;
  --left-end: 4vw;
  left: 64vw;
  animation: heartfall 13s linear infinite;
  animation-delay: -7s;
}

.heart:nth-child(19) {
  --size: 1vw;
  --left-ini: 2vw;
  --left-end: -7vw;
  left: 52vw;
  animation: heartfall 15s linear infinite;
  animation-delay: -9s;
}

.heart:nth-child(20) {
  --size: 0.4vw;
  --left-ini: 0vw;
  --left-end: 8vw;
  left: 5vw;
  animation: heartfall 8s linear infinite;
  animation-delay: -9s;
}

.heart:nth-child(21) {
  --size: 0.6vw;
  --left-ini: -9vw;
  --left-end: -2vw;
  left: 10vw;
  animation: heartfall 12s linear infinite;
  animation-delay: -3s;
}

.heart:nth-child(22) {
  --size: 0.8vw;
  --left-ini: -3vw;
  --left-end: -8vw;
  left: 54vw;
  animation: heartfall 11s linear infinite;
  animation-delay: -9s;
}

.heart:nth-child(23) {
  --size: 0.6vw;
  --left-ini: -7vw;
  --left-end: -8vw;
  left: 20vw;
  animation: heartfall 6s linear infinite;
  animation-delay: -3s;
}

.heart:nth-child(24) {
  --size: 0.4vw;
  --left-ini: 10vw;
  --left-end: -4vw;
  left: 68vw;
  animation: heartfall 10s linear infinite;
  animation-delay: -8s;
}

.heart:nth-child(25) {
  --size: 1vw;
  --left-ini: 3vw;
  --left-end: 5vw;
  left: 90vw;
  animation: heartfall 7s linear infinite;
  animation-delay: -9s;
}

.heart:nth-child(26) {
  --size: 1vw;
  --left-ini: -7vw;
  --left-end: 5vw;
  left: 71vw;
  animation: heartfall 13s linear infinite;
  animation-delay: -2s;
}

.heart:nth-child(27) {
  --size: 0.6vw;
  --left-ini: 9vw;
  --left-end: 4vw;
  left: 2vw;
  animation: heartfall 15s linear infinite;
  animation-delay: -5s;
}

.heart:nth-child(28) {
  --size: 1vw;
  --left-ini: -5vw;
  --left-end: 3vw;
  left: 23vw;
  animation: heartfall 12s linear infinite;
  animation-delay: -1s;
}

.heart:nth-child(29) {
  --size: 0.4vw;
  --left-ini: -2vw;
  --left-end: -1vw;
  left: 39vw;
  animation: heartfall 14s linear infinite;
  animation-delay: -9s;
}

.heart:nth-child(30) {
  --size: 0.6vw;
  --left-ini: -9vw;
  --left-end: -5vw;
  left: 76vw;
  animation: heartfall 10s linear infinite;
  animation-delay: -5s;
}

.heart:nth-child(31) {
  --size: 0.4vw;
  --left-ini: -5vw;
  --left-end: 3vw;
  left: 14vw;
  animation: heartfall 8s linear infinite;
  animation-delay: -5s;
}

.heart:nth-child(32) {
  --size: 0.2vw;
  --left-ini: 5vw;
  --left-end: -1vw;
  left: 86vw;
  animation: heartfall 10s linear infinite;
  animation-delay: -9s;
}

.heart:nth-child(33) {
  --size: 0.4vw;
  --left-ini: -3vw;
  --left-end: 10vw;
  left: 66vw;
  animation: heartfall 6s linear infinite;
  animation-delay: -6s;
}

.heart:nth-child(34) {
  --size: 1vw;
  --left-ini: -3vw;
  --left-end: 6vw;
  left: 75vw;
  animation: heartfall 14s linear infinite;
  animation-delay: -9s;
}

.heart:nth-child(35) {
  --size: 1vw;
  --left-ini: -6vw;
  --left-end: 7vw;
  left: 22vw;
  animation: heartfall 7s linear infinite;
  animation-delay: -5s;
}

.heart:nth-child(36) {
  --size: 1vw;
  --left-ini: 3vw;
  --left-end: 4vw;
  left: 10vw;
  animation: heartfall 11s linear infinite;
  animation-delay: -10s;
}

.heart:nth-child(37) {
  --size: 0.6vw;
  --left-ini: 2vw;
  --left-end: 10vw;
  left: 95vw;
  animation: heartfall 13s linear infinite;
  animation-delay: -6s;
}

.heart:nth-child(38) {
  --size: 0.8vw;
  --left-ini: 5vw;
  --left-end: 8vw;
  left: 34vw;
  animation: heartfall 9s linear infinite;
  animation-delay: -10s;
}

.heart:nth-child(39) {
  --size: 0.8vw;
  --left-ini: 4vw;
  --left-end: 0vw;
  left: 80vw;
  animation: heartfall 11s linear infinite;
  animation-delay: -2s;
}

.heart:nth-child(40) {
  --size: 0.8vw;
  --left-ini: 1vw;
  --left-end: -7vw;
  left: 45vw;
  animation: heartfall 11s linear infinite;
  animation-delay: -4s;
}

.heart:nth-child(41) {
  --size: 0.2vw;
  --left-ini: 9vw;
  --left-end: 10vw;
  left: 82vw;
  animation: heartfall 8s linear infinite;
  animation-delay: -2s;
}

.heart:nth-child(42) {
  --size: 1vw;
  --left-ini: 9vw;
  --left-end: -9vw;
  left: 22vw;
  animation: heartfall 10s linear infinite;
  animation-delay: -6s;
}

.heart:nth-child(43) {
  --size: 0.6vw;
  --left-ini: 5vw;
  --left-end: 8vw;
  left: 66vw;
  animation: heartfall 11s linear infinite;
  animation-delay: -1s;
}

.heart:nth-child(44) {
  --size: 0.6vw;
  --left-ini: -5vw;
  --left-end: -2vw;
  left: 75vw;
  animation: heartfall 12s linear infinite;
  animation-delay: -4s;
}

.heart:nth-child(45) {
  --size: 0.2vw;
  --left-ini: 0vw;
  --left-end: 3vw;
  left: 2vw;
  animation: heartfall 7s linear infinite;
  animation-delay: -5s;
}

.heart:nth-child(46) {
  --size: 0.2vw;
  --left-ini: 8vw;
  --left-end: -3vw;
  left: 94vw;
  animation: heartfall 8s linear infinite;
  animation-delay: -9s;
}

.heart:nth-child(47) {
  --size: 0.6vw;
  --left-ini: -6vw;
  --left-end: -9vw;
  left: 95vw;
  animation: heartfall 11s linear infinite;
  animation-delay: -4s;
}

.heart:nth-child(48) {
  --size: 0.2vw;
  --left-ini: -5vw;
  --left-end: 6vw;
  left: 34vw;
  animation: heartfall 10s linear infinite;
  animation-delay: -6s;
}

.heart:nth-child(49) {
  --size: 0.8vw;
  --left-ini: 4vw;
  --left-end: 8vw;
  left: 22vw;
  animation: heartfall 12s linear infinite;
  animation-delay: -10s;
}

.heart:nth-child(50) {
  --size: 0.2vw;
  --left-ini: -4vw;
  --left-end: 4vw;
  left: 100vw;
  animation: heartfall 14s linear infinite;
  animation-delay: -9s;
}
