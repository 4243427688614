.accountPanel {
  height: 100%;

  display: flex;
  flex-direction: column;

  > .header {
    flex: 0 0 auto;
    padding-top: 7px;
  }

  > .content {
    flex: 1 1 auto;
    height: 0;
    overflow: auto;
  }
}

@media all and (max-width: 768px) {
  .accountPanel {
    > .content {
      height: auto;
      overflow-y: none;
    }
  }
}
