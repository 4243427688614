.homePage {
  height: calc(100% - 2rem);
  margin: 1rem;

  display: flex;

  > * {
    margin-left: 1rem;
  }

  > *:first-child {
    margin-left: 0;
  }
}

.accountList {
  flex: 0 0 25%;
}

.accountPanel {
  flex: 1 1 0;
}

@media all and (max-width: 768px) {
  .homePage {
    flex-direction: column;

    > * {
      margin-top: 1rem;
      margin-left: 0;
    }

    > *:first-child {
      margin-top: 0;
    }
  }

  .accountList {
    flex: 0 0 auto;
  }

  .accountPanel {
    flex: 0 0 auto;
  }
}
