.clickableTableCell {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.iamRoleName {
  width: 100%;
}

.iamRoleButtons {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.deleteButton, .iamBtn {
  font-size: 75%;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  white-space: nowrap;
  padding: 0.25em 0.4em;

  &:hover, & > svg:hover {
    color: #fff;
    text-decoration: none;
    background-color: #5a5a5a !important;
    border-color: #5a5a5a;
  }
}

// .iamBtn {
//   display: inline-block;
//   color: #fff;

//   font-size: 75%;
//   line-height: 1;
//   text-align: center;
//   vertical-align: baseline;
//   border-radius: 0.25rem;
//   white-space: nowrap;
//   padding: 0.25em 0.4em;
//   background-color: #5a5a5a;
//   border-color: #5a5a5a;
//   border: 1px solid transparent;

//   &:hover {
//     color: #fff;
//     text-decoration: none;
//     background-color: #5a5a5a !important;
//     border-color: #5a5a5a;
//   }
// }

.iamRoleList {
  > tbody {
    max-height: 100%;
    overflow-y: auto;
    display: block;
  }

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td {
    width: 100%;
  }
}

@media all and (max-width: 768px) {
  .iamRoleList {
    > tbody {
      max-height: none;
      overflow-y: none;
    }
  }
}
