.announcementWrapper {
  display: flex;
  align-content: center;
  justify-content: center;
}

.announcement {
  position: absolute !important;
  z-index: 9999;
}
