.deleteButton {
  // font-size: 75%;
  // line-height: 1;
  // text-align: center;
  // vertical-align: baseline;
  // border-radius: 0.25rem;
  // white-space: nowrap;
  // padding: 0.25em 0.4em;
  float: right;
}

// .saveButton {
//   font-size: 75%;
//   line-height: 1;
//   text-align: center;
//   vertical-align: baseline;
//   border-radius: 0.25rem;
//   white-space: nowrap;
//   padding: 0.25em 0.4em;
//   float: right;
// }

.deleteLoad {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  max-width: 25em;
}
