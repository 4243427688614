body {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

.fullHeight {
  height: 100%;
}

// TODO Find a way to use styles dynamically from theme.ts instead of hardcoding them here - BW 10/7/20
$primary-color: #007bc7;
$secondary-color: #5a5a5a;
$attention-color: #c84630;

$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'danger': $attention-color,
);

@import '~bootstrap/scss/bootstrap';
