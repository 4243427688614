.accountItem {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;

  > * {
    margin-top: 1rem;
    flex: 0 0 auto;
  }

  > *:first-child {
    margin-top: 0px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  > * {
    margin-left: 5px;
  }

  > *:first-child {
    margin-left: 0;
  }
}

.names {
  flex: 1 0 8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
