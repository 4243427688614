.routes {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.nav {
  flex: 0 0 auto;
}

.content {
  flex: 1 1 0;
}
